import { useRouter } from 'next/router';
import { withLocale } from '@lib/translations/locales';
import {
  getPage,
  getAllPages,
  getLocales,
  getHeader,
  getFooter,
  getClient
} from '@services/contentful/api';
import Landing from '@components/landing';
import PageHead from '@components/pageHead';
import { PageContentTypes } from '@constants/index';
import ErrorComponent from './_error';

/**
 * @class
 * @param {Object} headerData header component data
 * @param {Object} pageData Current landing page data based on slug
 * @param {Object} footerData footer component data
 * @param {Object} locales Data for available locales
 *
 * @returns Landing page Component
 */

export default function LandingPage({
  headerData,
  pageData,
  footerData,
  locales
}) {
  const router = useRouter();
  if (!pageData) {
    return (
      <ErrorComponent
        statusCode={404}
        headerData={headerData}
        footerData={footerData}
        locales={locales}
      />
    );
  }
  const seoMetadata = pageData?.seoMetadata?.fields;
  return (
    <>
      <PageHead {...seoMetadata} />
      <Landing
        pageData={pageData}
        headerData={headerData}
        footerData={footerData}
        locales={locales}
        slug={
          router?.asPath?.split('/')[1]?.includes('?')
            ? router?.asPath
                ?.split('/')[1]
                .substring(0, router?.asPath?.split('/')[1].indexOf('?'))
            : router?.asPath?.split('/')[1]
        }
      />
    </>
  );
}

export const getStaticProps = withLocale(
  async (locale, { params, preview }) => {
    const slug = String(params.slug);
    const res = await getClient(preview).getEntries({
      content_type: PageContentTypes.LandingPage,
      'fields.slug': params.slug,
      // 'fields.slug': `/`,
      'metadata.tags.sys.id[in]': 'homeEnergy',
      include: 8
    });
    const headerData = await getHeader({ preview });

    const footerData = await getFooter({ preview });
    const locales = await getLocales(preview);
    if (!res?.items?.[0]?.fields) {
      return {
        notFound: true,
        revalidate: 10
      };
    }
    return {
      props: {
        pageData: res?.items?.[0]?.fields || {},
        ...headerData,
        ...footerData,
        locales
      },
      revalidate: process.env.NEXT_PUBLIC_REVALIDATION_TIME || 1000
    };
  }
);

export async function getStaticPaths() {
  const locales = await getLocales();
  const pages = await getAllPages(PageContentTypes.LandingPage);
  const paths = [];

  //   for (const locale of locales) {
  const slugs = pages
    .filter(p => p.fields.slug !== 'home-page')
    .map(p => p.fields.slug);
  slugs.forEach(s => {
    paths.push({
      params: {
        locale: 'en',
        slug: s
      }
    });
  });
  paths.push({
    params: {
      locale: 'en',
      slug: '/'
    }
  });
  return {
    paths,
    fallback: 'blocking' // can also be true or 'blocking'
  };
}
